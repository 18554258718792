import React, { useState } from 'react'
import DoctorImage from '../../Assets/DoctorImage.png'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css";
import servicios3 from '../../Assets/servicios3.png'
import servicios4 from '../../Assets/servicios4.png'
import servicios5 from '../../Assets/servicios5.jpg'
import servicios6 from '../../Assets/servicios6.jpg'
import servicios7 from '../../Assets/servicios7.jpg'
import servicios8 from '../../Assets/servicios8.jpg'
import Marker from '../../Assets/Marker.png'
import map from '../../Assets/map.png'
import Foto1 from '../../Assets/Mask group (7).png'
import Foto2 from '../../Assets/Mask group (8).png'
import iconortodoncia from '../../Assets/iconortodoncia.png'
import { NavLink } from 'react-router-dom';

function Homescreen() {

    const [display, setDisplay] = useState('hidden')

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };


    return (
        <div className='bg-white'>
            <div className='h-min'>
                <div className='bg-[#A1AC97] w-full flex flex-col justify-center items-center text-[white]'>
                    <div className='2xl:max-w-[1200px] md:max-w-[1000px] mx-auto flex flex-col justify-center text-center items-center gap-5 py-28 md:py-36 2xl:py-52 2xl:mb-6 p-5'>
                        <h1 className='nanum font-normal text-5xl md:text-7xl text-center'>Ven a sonreir con nosotros</h1>
                        <h2 className='text-center text-lg font-normal leading-5 questrial md:w-[60%]'>Ofrecemos un equipo de expertos altamente calificados y tecnología de vanguardia para cuidar de tu salud bucal y tu sonrisa.</h2>
                        <a href='https://ff.healthatom.io/03OdML'>
                            <button className='bg-white px-5 h-16 text-[#A1AC97] text-base md:text-lg rounded-2xl'>

                                Agenda una experiencia N&S
                            </button>
                        </a>
                    </div>
                </div>
                <div className='bg-[#515151] text-[#A1AC97] w-full  flex flex-col justify-center items-center relative md:rounded-b-[20rem]'>
                    <img src={DoctorImage} alt='Doctor' className='w-[300px] md:w-[560px] 2xl:w-[700px] absolute top-[-16%] md:top-[-22%] 2xl:top-[-32%]' />
                    <div className=' w-full 2xl:max-w-[1200px] md:max-w-[1000px] mx-auto flex justify-center items-center flex-col md:pb-8 2xl:py-20 mt-24 md:mt-56 2xl:mt-38 p-5 md:p-0'>
                        <h1 className='nanum font-normal text-4xl md:text-6xl md:text-left text-center md:self-start'>Dr. Jaime Nazar</h1>
                        <p className='md:ml-5 questrial md:self-start mb-6'>Cirujano dentista- Ortodoncista</p>
                        <div className=' md:self-end md:w-[70%] gap-5 flex flex-col'>
                            <p className='md:self-end text-center md:text-left'>El Dr. Jaime Nazar, con más de 15 años de experiencia en la odontología, se tituló de la Universidad de Chile con distinción máxima y luego cursó la especialidad de Ortodoncia y Ortopedia Dentomaxilofacial en la misma institución, dedicándose desde entonces a buscar cómo mejorar la experiencia de los pacientes en sus tratamientos odontológicos con los mejores resultados.</p>
                            <div className='md:self-start self-center'>
                                <a href='https://ff.healthatom.io/03OdML'>
                                    <button className='bg-[#A1AC97] p-5 text-[white] rounded-2xl md:self-start'>
                                        Habla conmigo
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col justify-center items-center text-[#A1AC97]'>
                <ScrollAnimation animateIn='animate__fadeInLeft' className='2xl:max-w-[1200px] md:max-w-[1000px] mx-auto p-5'>
                    <div className=' flex flex-col md:flex-row justify-between text-center items-center gap-5 py-10'>
                        <h3 className='nanum text-2xl md:text-6xl md:text-left font-normal w-[80%]'>
                            Realiza tu
                            tratamiento
                            Invisalign
                            con nosotros
                        </h3>
                        <div className='w-full'>
                            <div className='flex justify-between items-center border-b-[#95A5A1] border-b-[1px] pb-3'>
                                <div className='flex items-center gap-5 '>
                                    <img src={iconortodoncia} className='w-[50px]' />
                                    <p className='text-xl questrial'>Consulta de Ortodoncia</p>
                                </div>

                            </div>
                            <div className='flex flex-col p-5 gap-5'>
                                <p className='text-justify questrial'>La experiencia comienza conversando y analizando en conjunto los motivos de tu consulta, para buscar la mejor alternativa que pueda adecuarse a tus requerimientos y expectativas, tanto estéticas como funcionales. Previamente, nos encargaremos de revisar todo lo necesario para que tengas tu boca saludable y así comenzar tu tratamiento de ortodoncia de la mejor manera. Unos dientes alineados no solo mejoran la estética de tu sonrisa y tu cara, sino que también promueven una función masticatoria más eficiente y una salud bucal óptima.</p>
                                <a href='https://ff.healthatom.io/03OdML' className='self-start'>
                                    <button className='bg-[#A1AC97] px-[35px] text-[white] questrial self-start mb-1 py-3 rounded-2xl'>Agendar</button>
                                </a>
                            </div>
                            <div className='border-b-[#95A5A1] border-[1px]'></div>


                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-5 my-20'>
                        <div className='flex flex-col md:flex-row justify-between items-center gap-5 md:gap-0'>
                            <div className='md:w-[300px] md:h-[300px] 2xl:w-[386px] 2xl:h-[386px]'>
                                <img src={Foto1} onMouseEnter={(e) => (e.currentTarget.src = servicios5)} onMouseLeave={(e) => (e.currentTarget.src = Foto1)} className='rounded-full h-[100%] w-[100%] transition-all object-cover' />
                            </div>
                            <div className='2xl:h-[386px] 2xl:w-[674px] md:w-[600px] md:h-[300px]'>
                                <img src={Foto2} onMouseEnter={(e) => (e.currentTarget.src = servicios6)} onMouseLeave={(e) => (e.currentTarget.src = Foto2)} className='h-[100%] w-[100%] rounded-3xl transition-all  object-cover' />
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row justify-between items-center gap-5 md:gap-0'>
                            <div className='md:w-[500px] md:h-[200px] 2xl:w-[676.26px] 2xl:h-[255.11px]'>
                                <img src={servicios3} onMouseEnter={(e) => (e.currentTarget.src = servicios7)} onMouseLeave={(e) => (e.currentTarget.src = servicios3)} className='rounded-2xl h-[100%] w-[100%] transition-all object-cover' />
                            </div>
                            <div className='2xl:h-[255.11px] 2xl:w-[386.44px] md:w-[380px] md:h-[200px]'>
                                <img src={servicios4} onMouseEnter={(e) => (e.currentTarget.src = servicios8)} onMouseLeave={(e) => (e.currentTarget.src = servicios4)} className='h-[100%] w-[100%] rounded-3xl transition-all object-cover' />
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
                <h3 className='nanum md:text-6xl text-3xl text-center font-normal mb-10'>Aquí nos puedes encontrar</h3>
                <div className='relative w-full flex '>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.200215530163!2d-70.58238275872326!3d-33.41802414424238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf197012727f%3A0x1b87ea24b4cb3e32!2sDel%20Inca%204446%2C%207580206%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1710346592948!5m2!1ses!2scl" style={{ width: '100%', height: 600 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Homescreen