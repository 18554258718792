import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import map from '../../Assets/map.png'
import Marker from '../../Assets/Marker.png'


function UbicacionScreen() {
    const [display, setDisplay] = useState('hidden')
    return (
        <div className='h-minw-full flex flex-col justify-center items-center text-[#A1AC97] rounded-b-[6rem] md:rounded-b-[15rem]'>
            <div className='2xl:max-w-[1200px] md:max-w-[1000px] mx-auto text-left flex flex-col justify-center items-center mt-[20%] md:mt-[10%] mb-20 p-5'>
                <div className='flex justify-between items-center flex-col text-center md:text-left'>
                    <h1 className='text-3xl 2xl:text-7xl md:text-5xl  md:whitespace-nowrap my-5 nanum font-extrabold'>DÓNDE NOS ENCUENTRAS</h1>
                    <div className='flex flex-col gap-5'>
                        <p className='text-base md:text-lg questrial'>Ubicados en el corazón de Las Condes, brindando fácil acceso a nuestros pacientes.</p>
                    </div>
                </div>
            </div>
            <div className='relative w-full flex '>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.200215530163!2d-70.58238275872326!3d-33.41802414424238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf197012727f%3A0x1b87ea24b4cb3e32!2sDel%20Inca%204446%2C%207580206%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1710346592948!5m2!1ses!2scl" style={{ width: '100%', height: 600 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default UbicacionScreen