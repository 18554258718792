import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

function Cards({ Name, img, description, ShortDescription, especialistas }) {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    return (
        <div className={`card md:w-80 w-full  bg-base-100 shadow-xl h-fit bg-transparent rounded-3xl`}>
            <figure className='w-full h-[180px] '><img src={img} className='object-cover object-top  h-full w-full' alt="Shoes" /></figure>
            <div className="card-body bg-white text-[#515151] rounded-b-3xl py-10 px-5">
                <h2 className="card-title questrial 2xl:text-2xl">{Name}</h2>
                <p className='questrial text-sm'>
                    {ShortDescription}
                </p>
                <div className="card-actions justify-end rounded-3xl mt-5">
                    <NavLink to='/consulta-detail' state={{ description: description, name: Name, img: img, especialistas: especialistas }} className="btn btn-primary bg-[#A1AC97] border-0 text-white">Conoce Más</NavLink>
                </div>
            </div>
        </div>
    )
}

export default Cards