import React, { useEffect } from 'react'
import { NavLink, useLocation } from "react-router-dom";

function ConsultaDetail() {

    const location = useLocation();
    const { description, name, img, especialistas } = location;

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        scrollToTop();
    }, []);


    return (
        <div className='w-full 2xl:max-w-[1200px] md:max-w-[1000px] mx-auto flex flex-col h-min 2xl:py-40 py-20 items-center justify-center text-[#A1AC97] gap-14 px-5'>
            <div className='bg-[#F6F6F6] w-full px-16 py-10 text-[#A1AC97] flex flex-col md:flex-row justify-between items-center gap-7 rounded-3xl mt-20'>
                <h1 className='md:text-5xl text-4xl text-center md:text-start'>{location.state.name}</h1>
                <img src={location.state.img} alt="img" className='2xl:w-[500px] 2xl:h-[300px] w-[300px]  rounded-3xl' />
            </div>
            <h3 className='text-4xl text-start self-start'>Nuestros especialistas</h3>
            {
                location.state.especialistas.map((especialista) => {
                    return (
                        <div className='w-full  text-[#A1AC97] flex flex-col justify-between items-start gap-10 rounded-3xl mt-5'>
                            <h2 className='text-2xl'>{especialista.Name}</h2>
                            <p className='text-base'>
                                {especialista.Description}
                            </p>
                        </div>
                    )
                })
            }
            <a href='https://ff.healthatom.io/03OdML' className='btn btn-primary bg-[#A1AC97] border-0 text-white self-start'>Agendar Consulta</a>
        </div>
    )
}

export default ConsultaDetail