import React from 'react'
import { NavLink } from 'react-router-dom'

function ContactoScreen() {
    return (
        <div className='2xl:h-min md:h-screen h-screen bg-[#A1AC97] w-full flex justify-start items-center text-[white] py-24 md:py-32 '>
            <div className='2xl:max-w-[1200px] md:max-w-[1000px] mx-auto w-full mt-[3.5%] p-5'>
                <h1 className='text-7xl nanum font-normal text-center md:text-start'>Habla con nosotros</h1>
                <div className='flex flex-col md:flex-row items-center justify-between mt-5 gap-10'>
                    <div className='flex flex-col md:flex-row gap-5 w-full justify-between items-center'>
                        <p className='questrial text-base md:text-lg md:w-[70%] text-center md:text-start'>Escríbenos y déjanos ser tu aliado en la solución de tus problemas dentales. Estamos aquí para ofrecerte la mejor atención y ayudarte a lograr una sonrisa saludable.</p>
                        <a href='https://ff.healthatom.io/03OdML'>
                            <p className='py-5 px-10 bg-[white] text-[#515151] text-center md:self-start rounded-2xl'>HABLEMOS</p>
                        </a>
                    </div>

                </div>

            </div>

        </div >
    )
}

export default ContactoScreen