import React from 'react'
import Logo from '../../Assets/Logo.png'


import { AiOutlineArrowUp } from 'react-icons/ai'
import { AiFillLinkedin } from 'react-icons/ai'
import { AiFillInstagram } from 'react-icons/ai'
import { RiWhatsappFill } from 'react-icons/ri'


const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

}




function Footer() {
    return (
        <div className={window.location.pathname == '/contacto' ? 'w-full bg-white text-[#A1AC97]' : 'w-full bg-[#515151] text-[#A1AC97]'}>
            <div className=' md:max-w-[1000px] 2xl:max-w-[1200px] mx-auto flex flex-col py-10 p-5'>
                <div className='w-full flex justify-between items-center'>
                    <div className='flex flex-col md:flex-row md:items-center md:justify-center gap-5 md:gap-0'>
                        <img src={window.location.pathname == '/contacto' ? Logo : Logo} alt='Logo' className='w-[120px] h-[60px] md:w-[200px] md:h-[100px] ' />
                    </div>
                    <div className='flex items-center flex-col-reverse md:flex-row md:gap-5 gap-0'>
                        <p className='text-center questrial text-base md:text-lg'>Volver al inicio</p>
                        <AiOutlineArrowUp onClick={backToTop} className='text-5xl cursor-pointer' />
                    </div>
                </div>
                <div className='w-full flex flex-col md:flex-row md:justify-between items-center justify-center my-10'>
                    <div>
                        <ul>
                            <li className='text-base md:text-lg questrial'>Consulta de ortodoncia</li>
                        </ul>
                    </div>
                    <p className='md:self-end text-base md:text-lg questrial'>
                        +569 36789232
                    </p>
                    <div className='flex flex-col md:self-end'>
                        <p className='text-base md:text-lg text-right questrial'> Del Inca 4446, of 1402 Las Condes</p>
                    </div>

                </div>
                <div className='flex'>
                    <a href='https://cl.linkedin.com/in/jaime-nazar-864308119'>
                        <AiFillLinkedin className='text-5xl cursor-pointer' />
                    </a>
                    <a href='https://wa.me/+56936789232'>
                        <RiWhatsappFill className='text-5xl cursor-pointer' />
                    </a>
                    <a href='https://instagram.com/dr.jaimenazar?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr'>
                        <AiFillInstagram className='text-5xl cursor-pointer' />
                    </a>
                </div>
            </div>

        </div>
    )
}

export default Footer