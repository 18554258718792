import React from 'react'
import DobleFoto from '../../Assets/DobleFoto.png'

function QuienesSomosScreen() {
    return (
        <div className=' h-min w-full text-center flex justify-center items-center text-[#A1AC97] md:py-40 py-20  relative  overflow-hidden '>
            <div className='absolute z-[-999] md:-right-[50rem] 2xl:-right-[40rem] md:top-56 hidden md:block '>
                <svg xmlns="http://www.w3.org/2000/svg" width="1107" height="1105" viewBox="0 0 1107 1105" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M241.909 136.286C201.032 207.087 195.796 288.686 220.634 360.305C146.19 374.604 78.1418 419.938 37.2646 490.74C-37.44 620.132 6.89305 785.585 136.285 860.289C208.526 901.997 292.007 906.601 364.66 880.012C377.961 956.225 423.688 1026.22 495.928 1067.93C625.32 1142.63 790.773 1098.3 865.477 968.907C906.354 898.106 911.591 816.508 886.752 744.888C961.196 730.589 1029.24 685.255 1070.12 614.454C1144.83 485.062 1100.49 319.609 971.101 244.904C898.86 203.196 815.379 198.593 742.726 225.181C729.426 148.968 683.699 78.9735 611.459 37.2656C482.067 -37.4389 316.614 6.89407 241.909 136.286Z" fill="#A1AC97" />
                </svg>
            </div>
            <div className='2xl:max-w-[1200px] md:max-w-[1000px] mx-auto w-full flex flex-col justify-center items-center gap-5 z-10 p-5 '>
                <h1 className='nanum text-5xl md:text-7xl z-10'>Quienes somos</h1>
                <p className='text-base md:text-lg text-center font-normal md:w-[70%] 2xl:w-[50%]'>"N&S, fundado por el Dr. Jaime Nazar y Javier Silva, Ingeniero Civil Industrial, representa un concepto innovador en la atención dental. Con altos estándares de atención, tecnología de vanguardia y una sólida formación académica, nos esforzamos por ser tu mejor elección para iniciar un tratamiento dental."</p>
                <img src={DobleFoto} alt='DobleFoto' className=' md:w-[70%]' />


            </div>

        </div>
    )
}

export default QuienesSomosScreen