import React, { useEffect, useState } from 'react'
import Logo from '../../Assets/Logo.png'
import ShortLogo from '../../Assets/ShortLogo.png'
import LogoGris from '../../Assets/LogoGris.png'
import ShortLogoGris from '../../Assets/ShortLogoGrey.png'
import { useSwipeable } from 'react-swipeable'
import { NavLink } from 'react-router-dom'

function Navbar() {
    const [navbar, setNavbar] = useState(true)
    const [routeName, setRouteName] = useState('')
    const [bgColor, setBgColor] = useState('')
    const [logoSrc, setLogoSrc] = useState(Logo)
    const handlers = useSwipeable({
        trackMouse: true,
        onSwipedLeft: () => setNavbar(!navbar),
    });

    const swipeOpenMenuStyles = {
        float: "right",
        position: "fixed",
        width: "100%",
        height: "100%",
    };


    const changeBackground = () => {
        if (window.scrollY >= 20) {
            document.querySelector('.fixed').style.backgroundColor = '#515151'
            if (window.location.pathname === '/servicios' || window.location.pathname == '/contacto' || window.location.pathname == '/') {
                document.querySelector('.fixed').style.backgroundColor = '#A1AC97'
            }
        } else {
            document.querySelector('.fixed').style.backgroundColor = 'transparent'
            setLogoSrc(Logo)
            if (window.location.pathname === '/servicios' || window.location.pathname == '/contacto' || window.location.pathname == '/') {
                setLogoSrc(LogoGris)
            }
        }

    }

    window.addEventListener('scroll', changeBackground)

    const toggleNavbar = () => {
        setNavbar(!navbar)

    }

    const getRouteName = () => {
        if (window.location.pathname === '/') {
            setRouteName('Inicio')
        } else if (window.location.pathname === '/servicios') {
            setRouteName('Servicios')
        } else if (window.location.pathname === '/ubicacion') {
            setRouteName('Ubicación')
        } else if (window.location.pathname === '/contacto') {
            setRouteName('Contacto')
        } else if (window.location.pathname === '/somos') {
            setRouteName('Quienes Somos')
        } else if (window.location.pathname === '/formulario') {
            setRouteName('Formulario')
        }
    }


    const setColors = () => {
        if (window.location.pathname === '/servicios' || window.location.pathname == '/contacto' || window.location.pathname == '/' || window.location.pathname == '/servicio-detail') {
            setBgColor('white')
        } else {
            setBgColor('#A1AC97')
        }

    }

    useEffect(() => {
        getRouteName()
    }, [])

    useEffect(() => {
        setColors()
    }, [])



    useEffect(() => {
        if (window.location.pathname === '/servicios' || window.location.pathname == '/contacto' || window.location.pathname == '/') {
            setLogoSrc(LogoGris)
        }
    }, [])




    return (
        <div className='fixed top-0 w-full bg-transparent z-30 '>
            <div {...handlers} style={{ ...swipeOpenMenuStyles }} className='hidden' />
            <div className='flex justify-between items-center p-5 md:p-8 2xl:max-w-[1500px] md:max-w-[1000px] mx-auto'>
                <div className={`hidden md:block text-[${bgColor}] nanum max-w-[90px]`}>
                    <p className='md:text-2xl 2xl:text-3xl text-3xl'>{routeName}</p>
                </div>
                <NavLink to='/' className='z-40 2xl:w-[200px] md:w-[160px] w-[140px]'>
                    {
                        !navbar ? <img src={Logo} alt='Logo' className='' /> : ''
                    }

                    {
                        navbar ? <img src={logoSrc} alt='Logo' id='logos' className='' /> : ''
                    }



                </NavLink>
                <div onClick={toggleNavbar} className={`text-[${bgColor}] text-center z-50`}>
                    <span className={!navbar ? 'md:text-2xl 2xl:text-3xl text-3xl cursor-pointer nanum font-normal text-[#A1AC97]' : 'md:text-2xl 2xl:text-3xl text-3xl cursor-pointer nanum font-normal'}>{!navbar ? 'Cerrar' : 'Menú'}</span>
                </div>
                <div className={!navbar ? 'fixed left-0 top-0 w-[100%] h-screen bg-[#515151] transition-all text-[#A1AC97]' : 'fixed right-[-100%]'}>
                    <div className='md:max-w-[1200px] mx-auto flex flex-col  items-start md:mt-[5rem] 2xl:mt-[10rem] mt-[5rem] h-full p-6'>
                        <ul className='flex justify-between flex-col h-[70%]'>
                            <li className='text-3xl py-5 hover:px-5 transition-all text-[#A1AC97] questrial font-normal'>
                                <a href='/'>INICIO</a>
                            </li>
                            <li className='text-3xl py-5 hover:px-5 transition-all text-[#A1AC97] questrial font-normal'>
                                <a href='/servicios'>SERVICIOS</a>
                            </li>
                            <li className='text-3xl py-5 hover:px-5 transition-all text-[#A1AC97] questrial font-normal'>
                                <a href='/ubicacion'>UBICACIÓN</a>
                            </li>
                            <li className='text-3xl py-5 hover:px-5 transition-all text-[#A1AC97] questrial font-normal'>
                                <a href='/somos'>QUIÉNES SOMOS</a>
                            </li>
                            <li className='text-3xl py-5 hover:px-5 transition-all text-[#A1AC97] questrial font-normal'>
                                <a href='/contacto'>CONTACTO</a>
                            </li>
                        </ul>
                        <p className={!navbar ? 'self-end text-sm text-left md:w-[45%] mt-10 md:mt-5 transition-all text-[#A1AC97]' : 'hidden'}>
                            “Atenderte con nosotros no solo te dejará una sonrisa radiante, sino que también cambiará tu forma de ver las visitas al dentista.”
                        </p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar