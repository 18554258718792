import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { MutatingDots } from 'react-loader-spinner';
import Modal from 'react-modal';

function FormularioScreen() {

    const [value, setValue] = useState({
        name: '',
        last_name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [count, setCount] = useState(0);
    const [modalopen, setModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [successForm, setSuccessForm] = useState('');

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '5rem',
            width: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };

    const handleInputChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }

    const counter = (e) => {
        const count = value.message.length;
        setCount(count);
    }


    const handleSubmit = async () => {
        setModalLoading(true)
        if (validation() === false) {
            setModalLoading(false)
            return
        }
        try {
            const response = await axios.post('https://rjp3igia05.execute-api.sa-east-1.amazonaws.com/production/formulario/form', value)
            if (response.status == 200) {
                setSuccessForm('success')
                setModalLoading(false)
                setModalOpen(true)
                setValue({
                    name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    message: ''
                })
            } else {
                setSuccessForm('error')
                setModalLoading(false)
                setModalOpen(true)
                setValue({
                    name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    message: ''
                })
            }

        } catch (error) {
            console.log(error)
        }

    }


    const validation = () => {
        if (value.name === '') {
            alert('Ingrese su nombre')
            return false
        } else if (value.last_name === '') {
            alert('Ingrese su apellido')
            return false
        } else if (validateEmail(value.email) === null) {
            alert('Ingrese un correo valido')
            return false
        } else if (validatePhone() === false) {
            alert('Ingrese su telefono')
            return false
        } else if (value.message === '') {
            alert('Ingrese un mensaje')
            return false
        }

    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validatePhone = () => {
        const regex = /^[0-9]+$/
        const phone = value.phone
        if (regex.test(phone) && phone.length === 9) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        counter()
    }, [value.mensaje])







    return (
        <div className='h-min w-full flex flex-col justify-center items-center text-[#A1AC97] rounded-b-[6rem] md:rounded-b-[15rem]'>
            <div className='2xl:max-w-[1200px] md:max-w-[1000px] mx-auto text-left flex flex-col justify-center items-center mt-[25%] md:mt-[10%] mb-20'>
                <Modal isOpen={modalLoading} style={customStyles} contentLabel='Example Modal'>
                    <div className=''>
                        <MutatingDots
                            height="100"
                            width="100"
                            color="#515151"
                            secondaryColor='#515151'
                            radius='12.5'
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />

                    </div>
                </Modal>
                <Modal isOpen={modalopen} style={customStyles} contentLabel='Example Modal'>
                    {
                        successForm === "success" ?
                            <div className='flex flex-col gap-5'>
                                <h3 className='text-[#A1AC97] text-5xl text-center'>Haz reservado tu hora con exito!</h3>
                                <p className='text-center text-[#A1AC97]  text-base md:text-lg'>Enviaremos a tu mail la confirmación de tu hora.<br></br> Gracias por atenderte con N&S Estudio Dental</p>
                                <button className='bg-[#A1AC97] self-center p-4 px-10 rounded-2xl text-white' onClick={() => setModalOpen(false)}>Cerrar</button>
                            </div>
                            :
                            <div className='flex flex-col gap-5'>
                                <h3 className='text-[#A1AC97] text-5xl text-center'>Ocurrio un Error!</h3>
                                <p className='text-center text-[#A1AC97]  text-base md:text-lg'>Verifique los datos o intentelo mas tarde</p>
                                <button className='bg-[#A1AC97] self-center p-4 px-10 rounded-2xl text-white' onClick={() => setModalOpen(false)}>Cerrar</button>

                            </div>
                    }

                </Modal>

                <div className='flex justify-center items-center flex-col p-5 md:p-0'>
                    <h1 className='nanum text-5xl md:text-7xl mb-5'>Formulario</h1>
                    <p className='questrial text-base md:text-lg md:w-[60%] text-center'>Complete nuestro sencillo formulario para agendar su cita de ortodoncia. Obtenga la sonrisa perfecta que siempre ha deseado de manera conveniente y personalizada.</p>
                </div>
                <div className='mt-10 w-full md:w-[50%] flex flex-col gap-5 p-5 md:p-0'>
                    <h2 className='text-5xl font-medium md:text-center nanum'>Contacto</h2>
                    <input value={value.email} name='email' onChange={handleInputChange} className='border-[black] bg-white border rounded-3xl p-4 w-full pl-10 text-[#A1AC97]' placeholder='Correo electronico'></input>
                </div>
                <div className='w-full md:mt-10 flex flex-col p-5'>
                    <h2 className='nanum text-5xl'>Datos</h2>
                    <div className='flex flex-col md:flex-row justify-between items-center gap-5 mt-5'>
                        <input value={value.name} name='name' onChange={handleInputChange} className='border-[black] bg-white border rounded-3xl p-4 w-full pl-10 text-[#A1AC97]' placeholder='Nombre'></input>
                        <input value={value.last_name} name='last_name' onChange={handleInputChange} className='border-[black] bg-white border rounded-3xl p-4 w-full pl-10 text-[#A1AC97]' placeholder='Apellido'></input>
                    </div>
                    <input value={value.phone} name='phone' onChange={handleInputChange} className='border-[black] bg-white border rounded-3xl p-4 w-full pl-10 text-[#A1AC97] mt-10' placeholder='Teléfono +56'></input>
                    <textarea value={value.message} name='message' id='areatext' maxLength={300} className='border-[black] bg-white border rounded-3xl p-4 w-full pl-10 h-[200px] text-[#A1AC97] mt-10 resize-none' placeholder='Mensaje' onChange={handleInputChange} ></textarea>


                    <a onClick={handleSubmit} className='bg-[#A1AC97] text-[white] self-end p-5 px-10 rounded-xl mt-5 cursor-pointer'>Enviar</a>

                </div>
            </div>
        </div>
    )
}

export default FormularioScreen