import Navbar from "./Components/Navbar/Navbar";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Homescreen from "./Components/Screens/Homescreen";
import Footer from "./Components/Footer/Footer";
import ServiciosScreen from "./Components/Screens/ServiciosScreen";
import UbicacionScreen from "./Components/Screens/UbicacionScreen";
import ContactoScreen from "./Components/Screens/ContactoScreen";
import QuienesSomosScreen from "./Components/Screens/QuienesSomosScreen";
import FormularioScreen from "./Components/Screens/FormularioScreen";
import ConsultaDetail from "./Components/Screens/ConsultaDetail";



function App() {
  return (
    <BrowserRouter basename="/">
      <Navbar />
      <Routes>
        <Route path="/" element={<Homescreen />} />
        <Route path="/servicios" element={<ServiciosScreen />} />
        <Route path="/ubicacion" element={<UbicacionScreen />} />
        <Route path="/somos" element={<QuienesSomosScreen />} />
        <Route path="/contacto" element={<ContactoScreen />} />
        <Route path="/formulario" element={<FormularioScreen />} />
        <Route path="/consulta-detail" element={<ConsultaDetail />} />
        <Route path="*" element={<Homescreen />} />

      </Routes>
      <Footer />
    </BrowserRouter>

  );
}

export default App;
