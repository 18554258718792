import React from 'react'
import Ortodancia from '../../Assets/invisalign.webp'
import Maxilofacial from '../../Assets/Maxilofacial.png'
import General from '../../Assets/General.png'
import Implantologia from '../../Assets/Implantologia.png'
import Rehabilitacion from '../../Assets/Rehabilitacion.png'
import Endononcia from '../../Assets/Endononcia.png'
import Odontopediatria from '../../Assets/Odontopediatria.png'
import Periodoncia from '../../Assets/Periodoncia.png'
import Estetica from '../../Assets/EsteticaFacial.png'
import Cards from '../utils/Cards'
import iconayuda from '../../Assets/Group 11.png'
import iconayuda2 from '../../Assets/Group 13.png'
import iconayuda3 from '../../Assets/Group 16.png'
import { NavLink } from 'react-router-dom'

function ServiciosScreen() {

    const cards = [
        {
            Image: Ortodancia,
            Name: 'Consulta de ortodoncia',
            ShortDescription: 'Corregimos la alineación de tus dientes y lograr una sonrisa perfecta.',
            especialistas: [
                {
                    Name: 'Dr. Jaime Nazar Osman',
                    Description: 'Dr. Jaime es Cirujano Dentista de la Universidad de Chile y especialista en Ortodoncia en la misma casa de estudios. Cuenta con 10 años de experiencia en ortodoncia, enfocándose en soluciones estéticas desde niños hasta adultos. Cuenta con certificación en Insivalign, dado su interés en ofrecer a sus pacientes las últimas opciones para los tratamientos de ortodoncia.'
                },
                {
                    Name: 'Dr. Ariel Cicarelli',
                    Description: 'Dr. Ariel Cicarelli: Ariel es oriundo de Punta Arenas, y es Cirujano Dentista de la Universidad de la Frontera de Temuco y es especialista en Ortodoncia de la Universidad de Chile. Tiene más de 18 años de experiencia en atención pública y privada, abarcando atención a niños, adolescentes y adultos. Cuenta con certificación en Invisalign.'
                }
            ],
        },
        {
            Image: Rehabilitacion,
            Name: 'Rehabilitación e Implantología',
            ShortDescription: 'Recupere la salud dental perdida y restaure su sonrisa y funcionalidad oral.',
            especialistas: [
                {
                    Name: 'Dr. Sebastián Nadeau',
                    Description: 'Dr. Sebastián Nadeau: Sebastían es Laboratorista dental del Instituto Profesional AIEP, de la \
                    Universidad Andrés Bello, Cirujano Dentista de la Universidad Diego Portales y Especialista en \
                    Implantología Buco-Maxilofacial de la Universidad de los Andes. Adicionalmente tiene formación \
                    internacional en odontología estética, digital, CAD-CAM y cirugía guiada.\n\
                    Gracias a esta formación, Sebastián es actualmente conferencista de cursos para odontólogos de \
                    cerámicas dentales y odontología digital, lo que lo especializa para atender a sus pacientes con un \
                    enfoque en la rehabilitación oral, implantología y estética dental.'
                }
            ],
        },
        {
            Image: Endononcia,
            Name: 'Endodoncia',
            especialistas: [
                {
                    Name: 'Dra. Constanza Chartier',
                    Description: 'Dra. Constanza Chartier: Constanza es Cirujano dentista de la Universidad de Valparaíso, y se \
                    especializó en Endodoncia con más de 15 años de experiencia. Además, es Diplomada en Cirugía \
                    Apical en la misma casa de estudios, y se mantiene perfeccionándose y actualizándose de las \
                    mejores prácticas en su especialidad para poder evaluar, diagnosticar y tratar los problemas \
                    dentales asociados con la pulpa dental y tejidos peridentarios. Utilizando equipos y tecnología de \
                    punta, puede atender tratamientos de conductos o procedimientos quirúrgicos en el caso que se \
                    necesite.'
                }
            ],
            ShortDescription: 'Descubra la confianza de mantener una sonrisa sana.',
        },
        {
            Image: Odontopediatria,
            Name: 'Odontopediatría',
            especialistas: [
                {
                    Name: 'Dra. Bárbara Azocar',
                    Description: 'Dra. Bárbara Azocar: Barbara es oriunda de Concepción, Cirujano Dentista de la Universidad de\
                    Concepción y es especialista en Odontopediatría de la Pontificia Universidad Católica de Chile.\
                    Además cuenta con diplomado en Ortodoncia Interceptiva y consejería en Lactancia Materna.\n\
                    Tiene experiencia en el servicio público y privado, y su práctica profesional abarca la atención\
                    desde los primeros meses de vida hasta la adolescencia.\n\
                    Con ella esperamos que tus hijos tengan la mejor experiencia, en un ambiente seguro y relajado y\
                    juntos podamos diagnosticar cualquier tema a tiempo.'

                }
            ],
            ShortDescription: 'Proporcionamos atención para sonrisas saludables y felices desde temprana edad.',
        },
        {
            Image: Estetica,
            Name: 'Estética Facial',
            especialistas: [
                {
                    Name: 'Dra. Paula Riquelme',
                    Description: 'Dra. Paula Riquelme: Paula es Cirujano Dentista especialista en Endodoncia de la Universidad de los Andes, Magister en Docencia Universitaria en Ciencias de la Salud de la Universidad San Sebastián. Tiene amplia experiencia en el área de trauma dentoalveolar, tratamientos clínicos bajo magnificación y dolor en el área endodóntica. Además, cuenta con experiencia en el área de rejuvenecimiento orofacial y redermalización, lo que nos ayudará a buscar soluciones estéticas y sutiles que ayudarán a mantener tu rostro como lo deseas.'
                }
            ],
            ShortDescription: 'Transforme su imagen resaltando sus rasgos brindando un aspecto más  radiante.',
        },
        {
            Image: Implantologia,
            Name: 'Disfunción y dolor orofacial',
            ShortDescription: 'Alivie el dolor y mejore la función bucal.',
            especialistas: [
                {
                    Name: 'Dra. Montserrat Casals Soderlund',
                    Description: 'Dra. Montserrat Casals Soderlund: Montserrat es Cirujano Dentista de la Universidad de los Andes, \
                    especialista en Trastornos Temporomandibulares y dolor orofacial en la Universidad Andrés Bello, \
                    con más de 10 años de experiencia. Además, realizó el Magister de Investigación e innovación en \
                    Odontología en la Universidad de los Andes.\n\
                    Actualmente es docente de Pregrado y Postgrado en la Universidad de los Andes.'
                }
            ]
        },
        {
            Image: Maxilofacial,
            Name: 'Cirugía Maxilofacial',
            especialistas: [
                {
                    Name: 'Dra. Emilia Barros',
                    Description: 'Dra. Emilia Barros: Emilia es Cirujano Dentista de la Universidad de los Andes y especialista en \n Cirugía y Traumatología Buco Maxilofacial de la Universidad de Chile. Actualmente es docente de \n la Universidad de los Andes.'
                }
            ],
            ShortDescription: 'Transforme resuelva problemas estructurales que llevan a resultados funcionales y estéticos.',


        },
        {
            Image: Periodoncia,
            Name: 'Periodoncia',
            especialistas: [
                {
                    Name: 'Dra. Constanza Santibáñez',
                    Description: 'Dra. Constanza Santibáñez: Constanza es Cirujano Dentista de la Universidad Mayor y Especialista\
                    en Periodoncia de la Universidad de Los Andes con casi 10 años de experiencia profesional. Con su\
                    preparación, podremos tratar las enfermedades que afectan al hueso, la encía y el cemento\
                    radicular, tejidos que le dan soporte al diente para aumentar la vida sana de tu sonrisa.'
                }
            ],
            ShortDescription: 'Preserve la salud de sus encías con tratamientos para prevenir y tratar enfermedades periodontales.',
        },
        {
            Image: General,
            Name: 'Odontología general',
            especialistas: [
                {
                    Name: 'Dra. Catalina Corso',
                    Description: 'Dra. Catalina Corso: Catalina es Cirujano Dentista de la Pontificia Universidad Católica de Chile y además cuenta con Diplomado de Ortodoncia Temprana en el Paciente Pediátrico realizado en la misma casa de estudios. Su interés es brindar una atención centrada en el paciente, tanto adultos como niños, pero siempre buscando resolver la causa raíz de los problemas que pueda presentar al momento de la primera consulta.'
                }
            ],
            ShortDescription: 'Proporcionamos cuidados para una salud bucal óptima y duradera.',
        }

    ]

    return (
        <>
            <div className='h-min md:h-min bg-[#A1AC97] w-full flex justify-center items-center text-[white] md:py-[5rem] 2xl:py-[8rem] scroll '>
                <div className=' 2xl:max-w-[1300px] md:max-w-[1000px] mx-auto flex flex-col justify-center items-center mt-[30%] md:mt-[2%] w-full'>
                    <div className='flex justify-center items-center flex-col text-center w-full mb-5'>
                        <h1 className='text-3xl 2xl:text-7xl md:text-7xl whitespace-nowrap my-5 nanum font-normal'>Nuestros servicios</h1>
                        <p className='text-base md:text-lg questrial leading-normal'>Ofrecemos odontología de alta calidad, para mantener tu sonrisa saludable y hermosa.</p>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-3 justify-items-center items-start gap-10 w-full p-5 container-grid relative'>
                        {
                            cards.map((card, index) => (
                                <Cards key={index} Name={card.Name} img={card.Image} description={card.Description} ShortDescription={card.ShortDescription} especialistas={card.especialistas} />
                            ))
                        }
                    </div>
                    <ul className=' md:w-[75%] 2xl:w-[70%] w-full my-10 flex flex-col justify-center items-center gap-10 p-5 text-[white]'>
                        <li className='flex justify-between w-full gap-5 items-center'>
                            <img src={iconayuda2} alt='icono' className='w-[50px] md:w-[50px] 2xl:w-[80px]' />
                            <p className='text-base md:text-lg questrial md:w-[85%]'>Te ayudamos a corregir problemas de alineación dental, lo que puede reducir el riesgo de caries y enfermedad de las encías, así como problemas de mordida, mejorando de esta manera la salud bucal en general.</p>
                        </li>
                        <li className='flex justify-between w-full gap-5 items-center'>
                            <img src={iconayuda} alt='icono' className=' w-[50px] md:w-[50px] 2xl:w-[80px]' />
                            <p className='text-base md:text-lg questrial w-[85%]'>Una sonrisa recta y atractiva mejora la confianza y la autoestima, lo que puede tener un impacto positivo en la vida social y profesional de una persona.</p>
                        </li>
                        <li className='flex justify-between w-full gap-5 items-center'>
                            <img src={iconayuda3} alt='icono' className='w-[50px]  md:w-[50px] 2xl:w-[80px]' />
                            <p className='text-base md:text-lg questrial w-[85%]'>La ortodoncia no solo mejora la apariencia, sino también la funcionalidad de la boca, permitiendo una masticación más eficiente y una mejor pronunciación, lo que facilita la comunicación</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className=' '>
                <video className='w-full md:h-[500px] object-contain md:object-contain' autoPlay loop muted controls>
                    <source src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/Simplesoluciones/VID-20231026-WA0005.MP4'} type="video/mp4" />
                </video>
            </div>
            <div className='h-min text-[#A1AC97] w-full flex relative overflow-hidden'>
                <div className='absolute md:-right-96 md:-top-64 z-[-1] hidden md:block '>
                    <svg xmlns="http://www.w3.org/2000/svg" width="916" height="918" viewBox="0 0 916 918" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M112.372 717.338C171.112 751.251 238.809 755.595 298.227 734.988C310.091 796.749 347.701 853.204 406.441 887.117C513.789 949.095 651.056 912.315 713.033 804.966C747.636 745.033 751.455 675.775 729.397 615.499C792.626 604.465 850.696 566.528 885.298 506.595C947.276 399.246 910.496 261.98 803.147 200.002C744.408 166.089 676.711 161.745 617.293 182.351C605.429 120.59 567.819 64.1341 509.079 30.2207C401.73 -31.7571 264.464 5.02332 202.486 112.372C167.884 172.305 164.065 241.564 186.123 301.84C122.894 312.875 64.8233 350.812 30.2208 410.745C-31.757 518.094 5.02334 655.36 112.372 717.338Z" fill="#A1AC97" />
                    </svg>
                </div>
                <div className=' 2xl:max-w-[1200px] md:max-w-[1000px] md:w-full mx-auto text-left flex '>
                    <div className='md:w-[50%] md:py-[8rem] p-5 md:p-0'>
                        <h3 className='text-3xl 2xl:text-7xl md:text-5xl md:text-left text-center mb-5 nanum font-normal md:w-[60%]'>Agenda con nosotros</h3>
                        <div className='flex flex-col items-start justify-between gap-5 '>
                            <p className='text-base md:text-lg questrial text-center md:text-left'>Da el primer paso hacia una sonrisa más saludable y hermosa. Estamos listos para atenderte de manera profesional y personalizada.</p>
                            <NavLink to='/formulario' className='self-center md:self-start my-5 '>
                                <a className='p-5 bg-[#A1AC97] text-[white] rounded-2xl cursor-pointer'>Agenda con nosotros</a>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ServiciosScreen